<template>
  <div>
        <div class="bookmark-wrapper">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="card card-bookmark-wrapper">
                        <div class="card-header text-center card-header-bookmark">
                            Bookmark
                        </div>
                        <div class="card-body">
                            <div class="card">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item bookmark-list-space" v-for="(data,index) in bookmark" :key="index">
                                        <router-link :to="{name:'surah', params:{no:data.id,bookmark:data.ayat}}" class="link-bookmark">
                                            <p class="d-inline-flex">
                                                {{index+1}}. {{data.surah}} : {{data.ayat}}
                                            </p>
                                            <p class="d-inline-flex float-right">
                                                {{data.surahArab}}
                                            </p>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            bookmark : [],
        }
    },
    mounted(){
        this.getBookmarks()
    },
    methods:{
        getBookmarks(){
            var storedNames = JSON.parse(localStorage.getItem("bookmark"));
            this.bookmark = storedNames
        }
    }
}
</script>

<style>

</style>